import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from "axios/index";

// axios.get(``).then(res => {
//     console.log(res.data);
// });

// axios.defaults.headers.common['Cookie'] = 'csrftoken=Cr2RQpIxNxeJNxsar7hLXM1YmfxYBPdqsJyj9Va0C8UpRvReOW8n1O3wHbbrkPtb; sessionid=3hqpk2wlr1vq41alyjqm6d9z164zzgb0';
axios.defaults.headers = {'X-CSRFToken': document.cookie.split('=')[1]};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
module.hot.accept();
