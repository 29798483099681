import React, {Component} from "react";
import Breadcrums from '../common/Breadcrums';
import axios from 'axios';


class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            permission: null,
            mess_types: [],
            contactId_filters: '',
            enable_cid_filters: false
        };
        this.arr = [];
        // console.log(this.props);
        this.perId = this.props.match.params.permissionId;
        this.checked = this.checked.bind(this);
        this.postCheckedId = this.postCheckedId.bind(this);
        // this.send = this.send.bind(this);
    }

    postCheckedId(val) {
      let push = this.state.mess_types.some(i => i === val);
      let newOrder = [].concat(this.state.mess_types);

        if(!push) {
            newOrder.push(val);
        } else {
            newOrder.splice(newOrder.indexOf(val), 1);
        }
        this.setState({mess_types: newOrder});
        this.updateData({message_types: newOrder, permission: this.state.permission, contactId_filters: this.state.contactId_filters, enable_cid_filters: this.state.enable_cid_filters});
    }

    checked(className, bool) {
        let inputs = [];
        let all = [];
        let {mess_types} = this.state;
        if(className) {
            inputs = document.querySelectorAll(`input.${className}`);
        } else {
            inputs = document.querySelectorAll("input[type='checkbox']");
        }
        for(let i = 0; i < inputs.length; ++i) {
            if(!inputs[i].classList.contains('cid_filters_flag')) {
                all.push(inputs[i]);
            }
            // all.push(inputs[i]);
        }
        all.map((item) => {
            if(bool) {
                if(!item.checked) {
                    mess_types.push(item.value);
                    return item.checked = true;
                }
            } else {
                if(item.checked) {
                    let index = mess_types.indexOf(item.value);
                    mess_types.splice(index, 1);
                    return item.checked = false;
                }
            }
            return false;
        });
        this.updateData({message_types: mess_types, permission: this.state.permission, contactId_filters: this.state.contactId_filters, enable_cid_filters: this.state.enable_cid_filters});
    }

    componentDidMount() {
        axios.get(`/api/user/permissions/${this.perId}/`)
            .then(res => {
                this.updateState(res.data);
            });
    }

    updateState = (data) => {
        this.setState({
            mess_types: data.message_types,
            permission: data,
            contactId_filters: data.contactId_filters,
            enable_cid_filters: data.enable_cid_filters
        })
    };

    handleInputSelect = (e) => {
        let data = {};

        data[e.target.name] = e.target.checked;
        this.updateData(data)
    };

    updateData = (data) => {
        axios.patch(`/api/user/permissions/${this.perId}/`, data)
            .then(res => {
                this.updateState(res.data);
            })
    };

    checkInput = (val) => {

        return this.state.mess_types.some(item => item === val);
    };



    send = () => {
        let {mess_types, permission, enable_cid_filters} = this.state;
        let data = {
            message_types: mess_types,
            permission: permission,
            contactId_filters: this.text.value,
            enable_cid_filters: enable_cid_filters
        };
        axios.patch(`/api/user/permissions/${this.perId}/`, data);
    };

    changeCidFilters = (e) => {
        this.updateData({message_types: this.state.mess_types, permission: this.state.permission, contactId_filters: this.state.contactId_filters, enable_cid_filters: e.target.checked});
    };

    render() {

        const {permission, contactId_filters} = this.state;

        return(permission &&
            <div className='settings'>
                <div role="main" className="mt-5 row justify-content-center">
                    <div className="col-9">
                        <Breadcrums users={true} access={true} address='Настройки'/>
                        <div className="row mt-5">
                            <h3>Настройка доступа для пользователя {permission.user}</h3>
                        </div>
                        {/*tabs*/}
                        <div className="row mt-1 tabs">
                            <div className="col-3">
                                <div className="row">
                                    <div className="col-5" style={{top: !this.state.tab ? '1px' : '0'}}>
                                        <div className="tab-1" style={{borderColor: !this.state.tab ? '#ddd' : '#fff', color: this.state.tab ? '#337ab7' : '#000'}} onClick={() => {this.setState({tab: 0})}}>
                                            Статистика
                                        </div>
                                    </div>
                                    <div className="col-6" style={{top: this.state.tab ? '1px' : '0'}}>
                                        <div className="tab-2" style={{borderColor: this.state.tab ? '#ddd' : '#fff', color: !this.state.tab ? '#337ab7' : '#000'}} onClick={() => {this.setState({tab: 1})}}>
                                            Фильтр событий
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*////*/}
                        {/*content*/}
                        {
                            !this.state.tab ?
                                <div className='row mt-2'>
                                    <div className="col">
                                        <div className="row">
                                            <label>
                                                <input type="checkbox"
                                                       name="is_show"
                                                       onClick={this.handleInputSelect}
                                                       defaultChecked={permission.is_show}
                                                />
                                                Включить статистику и добавить в отчет следующие колонки:
                                            </label>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label>
                                                    <input type="checkbox"
                                                           name="is_show_date"
                                                           defaultChecked={permission.is_show_date}
                                                           onClick={this.handleInputSelect}/> Дата
                                                </label> <br/>
                                                <label>
                                                    <input type="checkbox"
                                                           name="is_show_time"
                                                           defaultChecked={permission.is_show_time}
                                                           onClick={this.handleInputSelect}/> Время
                                                </label> <br/>
                                                <label>
                                                    <input type="checkbox"
                                                           name="is_show_object"
                                                           defaultChecked={permission.is_show_object}
                                                           onClick={this.handleInputSelect}/> Объект
                                                </label> <br/>
                                                <label>
                                                    <input type="checkbox"
                                                           name="is_show_status"
                                                           defaultChecked={permission.is_show_status}
                                                           onClick={this.handleInputSelect}/> Статус
                                                </label> <br/>
                                                <label>
                                                    <input type="checkbox"
                                                           name="is_show_text"
                                                           defaultChecked={permission.is_show_text}
                                                           onClick={this.handleInputSelect}/> Текст сообщения
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="row">
                                    <div className="col-4">
                                        <div className="row mt-1 block">
                                            <div className="col">
                                                <div className="row header">
                                                    <div className="col">Сообщения от радиобрелка</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(91)} onChange={() => {this.postCheckedId(91)}} value='91'/> Тревожная кнопка
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(119)} onChange={() => {this.postCheckedId(119)}} value='119'/> Начало задержки на вход
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-1 block">
                                            <div className="col">
                                                <div className="row header">
                                                    <div className="col">Периодические сообщения</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(96)} onChange={() => {this.postCheckedId(96)}} value='96'/> Охранный
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(97)} onChange={() => {this.postCheckedId(97)}} value='97'/> Тестовый
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(101)} onChange={() => {this.postCheckedId(101)}} value='101'/> Пропадание охранных (от ретранслятора)
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(103)} onChange={() => {this.postCheckedId(103)}} value='103'/> Пропадание тестовых (от ретранслятора)
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(104)} onChange={() => {this.postCheckedId(104)}} value='104'/> Восстановление охранных (от ретранслятора)
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-1 block">
                                            <div className="col">
                                                <div className="row header">
                                                    <div className="col">Сообщения от наряда охраны</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(17)} onChange={() => {this.postCheckedId(17)}} value='17'/> Конец осмотра
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(20)} onChange={() => {this.postCheckedId(20)}} value='20'/> Тревога, вызов подкрепления
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(21)} onChange={() => {this.postCheckedId(21)}} value='21'/> Периодический сигнал 'Тревога наряда'
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(48)} onChange={() => {this.postCheckedId(48)}} value='48'/> Кнопка ЦКН
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-1 block">
                                            <div className="col">
                                                <div className="row header">
                                                    <div className="col">Системные сообщения</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>
                                                            <input className='defect' type="checkbox" defaultChecked={this.state.tab && this.checkInput(50)} onChange={() => {this.postCheckedId(50)}} value='50'/> На АБИ 'Двойник'
                                                        </label><br/>
                                                        <label>
                                                            <input className='defect' type="checkbox" defaultChecked={this.state.tab && this.checkInput(61)} onChange={() => {this.postCheckedId(61)}} value='61'/> Пропал на АБИ прибор
                                                        </label><br/>
                                                        <label>
                                                            <input className='defect' type="checkbox" defaultChecked={this.state.tab && this.checkInput(63)} onChange={() => {this.postCheckedId(63)}} value='63'/> Восстановление на АБИ прибора
                                                        </label><br/>
                                                        <label>
                                                            <input className='defect' type="checkbox" defaultChecked={this.state.tab && this.checkInput(116)} onChange={() => {this.postCheckedId(116)}} value='116'/> Помеха в радиоканале
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(117)} onChange={() => {this.postCheckedId(117)}} value='117'/> Сброс ЦПУ
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(120)} onChange={() => {this.postCheckedId(120)}} value='120'/> Кнопка 'ТЕСТ'
                                                        </label><br/>
                                                        <label>
                                                            <input className='defect' type="checkbox" defaultChecked={this.state.tab && this.checkInput(124)} onChange={() => {this.postCheckedId(124)}} value='124'/> Два прибора контролируют ЛС
                                                        </label><br/>
                                                        <label>
                                                            <input className='defect' type="checkbox" defaultChecked={this.state.tab && this.checkInput(125)} onChange={() => {this.postCheckedId(125)}} value='125'/> ## Сообщение ##
                                                        </label><br/>
                                                        <label>
                                                            <input className='defect' type="checkbox" defaultChecked={this.state.tab && this.checkInput(126)} onChange={() => {this.postCheckedId(126)}} value='126'/> ## Ошибка ##
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-1 block">
                                            <div className="col">
                                                <div className="row header">
                                                    <div className="col">Системные температуры</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(66)} onChange={() => {this.postCheckedId(66)}} value='66'/> Нет значения температуры
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(76)} onChange={() => {this.postCheckedId(76)}} value='76'/> Температура 1-го датчика
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(77)} onChange={() => {this.postCheckedId(77)}} value='77'/> Температура 2-го датчика
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(78)} onChange={() => {this.postCheckedId(78)}} value='78'/> Температура 3-го датчика
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(79)} onChange={() => {this.postCheckedId(79)}} value='79'/>￼Температура 4-го датчика
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="row mt-1 block">
                                            <div className="col">
                                                <div className="row header">
                                                    <div className="col">Состояние питания прибора</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(112)} onChange={() => {this.postCheckedId(112)}} value='112'/> Пропадание 220
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(113)} onChange={() => {this.postCheckedId(113)}} value='113'/> Восстановление 220
                                                        </label><br/>
                                                        <label>
                                                            <input className='alarm' type="checkbox" defaultChecked={this.state.tab && this.checkInput(114)} onChange={() => {this.postCheckedId(114)}} value='114'/> Разряд АКБ
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(115)} onChange={() => {this.postCheckedId(115)}} value='115'/> Восстановление АКБ
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-1 block">
                                            <div className="col">
                                                <div className="row header">
                                                    <div className="col">Тревожные сообщения</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>
                                                            <input className='alarm' type="checkbox" defaultChecked={this.state.tab && this.checkInput(80)} onChange={() => {this.postCheckedId(80)}} value='80'/> Нарушение охранного шлейфа
                                                        </label><br/>
                                                        <label>
                                                            <input className='alarm' type="checkbox" defaultChecked={this.state.tab && this.checkInput(82)} onChange={() => {this.postCheckedId(82)}} value='82'/> Нарушение пожарной зоны (режим ПОЖАР)
                                                        </label><br/>
                                                        <label>
                                                            <input className='alarm' type="checkbox" defaultChecked={this.state.tab && this.checkInput(100)} onChange={() => {this.postCheckedId(100)}} value='100'/> Тревожный периодический сигнал
                                                        </label><br/>
                                                        <label>
                                                            <input className='alarm' type="checkbox" defaultChecked={this.state.tab && this.checkInput(84)} onChange={() => {this.postCheckedId(84)}} value='84'/> Скрытая тревога
                                                        </label><br/>
                                                        <label>
                                                            <input className='alarm' type="checkbox" defaultChecked={this.state.tab && this.checkInput(85)} onChange={() => {this.postCheckedId(85)}} value='85'/> Нарушение пожарной зоны (режим ВНИМАНИЕ)
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(59)} onChange={() => {this.postCheckedId(59)}} value='59'/> Нарушен ТЕМПЕР прибора КЛС
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(60)} onChange={() => {this.postCheckedId(60)}} value='60'/> Нарушен ТЕМПЕР объектового прибора
                                                        </label><br/>
                                                        <label>
                                                            <input className='alarm access_control' type="checkbox" defaultChecked={this.state.tab && this.checkInput(65)} onChange={() => {this.postCheckedId(65)}} value='65'/> Подбор кода
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-1 block">
                                            <div className="col">
                                                <div className="row header">
                                                    <div className="col">Команды управления объектом</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>
                                                            <input className='access_control' type="checkbox" defaultChecked={this.state.tab && this.checkInput(5)} onChange={() => {this.postCheckedId(5)}} value='5'/> Доступ
                                                        </label><br/>
                                                        <label>
                                                            <input className='access_control' type="checkbox" defaultChecked={this.state.tab && this.checkInput(6)} onChange={() => {this.postCheckedId(6)}} value='6'/> Постановка
                                                        </label><br/>
                                                        <label>
                                                            <input className='access_control' type="checkbox" defaultChecked={this.state.tab && this.checkInput(64)} onChange={() => {this.postCheckedId(64)}} value='64'/> Снятие
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(51)} onChange={() => {this.postCheckedId(51)}} value='51'/> Доступ Установщик
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(11)} onChange={() => {this.postCheckedId(11)}} value='11'/> Сообщение об выполнении ком.управл.выходом прибора
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-1 block">
                                            <div className="col">
                                                <div className="row header">
                                                    <div className="col">Неисправность и восстановление зон</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>
                                                            <input className='defect' type="checkbox" defaultChecked={this.state.tab && this.checkInput(57)} onChange={() => {this.postCheckedId(57)}} value='57'/> Неисправность пожарной зоны
                                                        </label><br/>
                                                        <label>
                                                            <input className='defect' type="checkbox" defaultChecked={this.state.tab && this.checkInput(58)} onChange={() => {this.postCheckedId(58)}} value='58'/> Неисправность тревожной кнопки
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(81)} onChange={() => {this.postCheckedId(81)}} value='81'/>￼Восстановление охранного шлейфа
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(83)} onChange={() => {this.postCheckedId(83)}} value='83'/> Восстановление пожарной зоны
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(90)} onChange={() => {this.postCheckedId(90)}} value='90'/> Восстановление шлейфа 'Тревожная кнопка'
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="row mt-1 block">
                                            <div className="col">
                                                <div className="row header">
                                                    <div className="col">Дополнительные сообщения</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(86)} onChange={() => {this.postCheckedId(86)}} value='86'/> Кнопка 'ПОЖАР'
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(87)} onChange={() => {this.postCheckedId(87)}} value='87'/> Кнопка 'МИЛИЦИЯ'
                                                        </label><br/>
                                                        <label>
                                                            <input type="checkbox" defaultChecked={this.state.tab && this.checkInput(88)} onChange={() => {this.postCheckedId(88)}} value='88'/> Кнопка 'СКОРАЯ ПОМОЩЬ'
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col-4">Все сигналы</div>
                                                    <div className="col-2">
                                                        <div className="row">
                                                            <div className="col-4 button" onClick={() => {this.checked('', true)}}>+</div>
                                                            <div className="col-4 button" onClick={() => {this.checked('', false)}}>-</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-4">Тревоги</div>
                                                    <div className="col-2">
                                                        <div className="row">
                                                            <div className="col-4 button" onClick={() => {this.checked('alarm', true)}}>+</div>
                                                            <div className="col-4 button" onClick={() => {this.checked('alarm', false)}}>-</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-4">Неисправности</div>
                                                    <div className="col-2">
                                                        <div className="row">
                                                            <div className="col-4 button" onClick={() => {this.checked('defect', true)}}>+</div>
                                                            <div className="col-4 button" onClick={() => {this.checked('defect', false)}}>-</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-4">Контроль доступа</div>
                                                    <div className="col-2">
                                                        <div className="row">
                                                            <div className="col-4 button" onClick={() => {this.checked('access_control', true)}}>+</div>
                                                            <div className="col-4 button" onClick={() => {this.checked('access_control', false)}}>-</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg">
                                        <div className="row mt-auto block">
                                            <div className="col">
                                                <div className="row header">
                                                    <div className="col">Фильтры ContactID</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-2">
                                                        <label>
                                                            <input type="checkbox" className='cid_filters_flag' defaultChecked={this.state.enable_cid_filters} onChange={this.changeCidFilters}/> Включить
                                                        </label><br/>
                                                    </div>
                                                    <div className="col-6">
                                                        <input
                                                            type='text'
                                                            style={{width: '100%'}}
                                                            ref={node => this.text = node}
                                                            defaultValue={contactId_filters}
                                                            disabled={!this.state.enable_cid_filters}
                                                            placeholder="example: 3-6, 18-21, 80, 81, 99-100, 576"
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <div className="col ml-auto col-md-auto" style={{paddingRight: '0'}}>
                                                            {/*<div className="send-btn mb-auto button" onClick={this.state.enable_cid_filters ? this.send : null}>Отправить</div>*/}
                                                            <div className="send-btn mb-auto button" onClick={this.send}>Сохранить</div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                    </div>
                </div>
            </div>
        );
    }
}

export default Settings;