import React, {Component} from "react";
import {Redirect} from "react-router";
import axios from "axios";


class Logout extends Component {

    componentWillMount() {
        axios.post('/api/user/logout/')
    }

    render(){
        return (
            <Redirect to="/login" />
        )
    }
}

export default Logout;
