import React, {Component} from "react";
import Breadcrums from '../common/Breadcrums';
import search from "../../imgs/search.png";
import axios from 'axios';
import User from "./User";
import EditUser from "./EditUser";

const Modal = (props) => (
    <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '10'}}>
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-body">
                    {props.message}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={props.close}>Ok</button>
                </div>
            </div>
        </div>
    </div>
);

const CreatingForm = (props) => (
    <form className="form-group row" id={'create-user'} onSubmit={props.onSubmit}>
        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-4">
            <input
                type="text"
                name="username"
                placeholder='Логин'
                className="form-control"
                required
            />
        </div>
        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-4">
            <input
                type="email"
                name="email"
                placeholder='E-mail'
                className="form-control"
                required
            />
        </div>
        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-4">
            {
                props.created
                    ? <button type="submit" className="btn btn-success active" disabled>
                        Пользователь создан
                      </button>
                    : <button type="submit" className="btn btn-info active">Создать пользователя</button>
            }
        </div>
        <div className="col">
          {props.error}
        </div>
    </form>
);

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            newUser: null,
            search: '',
            showModal: false,
        };
        this.createUser = this.createUser.bind(this);
        this.editUser = this.editUser.bind(this);
        this.saveEdits = this.saveEdits.bind(this);
    }

    saveEdits() {
        let id = this.edit.querySelector("input[type='hidden']").value;
        let username = this.edit.querySelector("input[placeholder='Логин']").value;
        let first_name = this.edit.querySelector("input[placeholder='Ф.И.О.']").value;
        let email = this.edit.querySelector("input[placeholder='E-mail']").value;
        let is_staff = this.edit.querySelector("input[type='checkbox']").checked;
        axios.patch(`/api/user/${id}/`, {
            username: username,
            first_name: first_name,
            email: email,
            is_staff: is_staff,
        }).then(() => {
            this.componentDidMount();
        });
    }

    editUser(user) {
        this.edit.querySelector("input[placeholder='Логин']").value = user.username;
        this.edit.querySelector("input[placeholder='Ф.И.О.']").value = user.first_name;
        this.edit.querySelector("input[placeholder='E-mail']").value = user.email;
        this.edit.querySelector("input[type='hidden']").value = user.id;
    }
    showNewUser = (data) => {
        this.setState({
            newUser: data
        });
        this.showing = setTimeout(() => {
            this.setState({
                newUser: null
            })
        }, 2000);
        this.loadUsersList();
    };
    handleUserSearch = (e) => {
        this.setState({
            search: e.target.value
        })
    };
    createUser(e) {
        e.preventDefault();
        const data = new FormData(e.target);
        axios.post('/api/user/create/', data)
            .then(res => {
                this.setState({
                    newUser: res.data
                }, this.showNewUser.bind(this, res.data))
            })
            .catch(err => {
                if (err.response) {
                    if(err.response.status === 400) {
                        this.setState({
                            showModal: err.response.data['non_field_errors'] || err.response.data['username']
                        });
                    }
                }

            });
    }

    searchByName = () => {
        const searchS = this.state.search;
        return this.state.users.filter(i => (
            i.username.includes(searchS) || i.first_name.includes(searchS) || i.last_name.includes(searchS)

        ))
    };

    loadUsersList = () => {
        axios.get('/api/user/list/').then(res => {
            this.setState({
                users: res.data,
            });
        });
    };

    checkUser = (id) => {
        let new_list = this.state.users;
        new_list.map(i => i.checked = (i.id === id) );
        this.setState({
            users: new_list
        })
    };

    componentDidMount() {
        this.loadUsersList()
    }

    updateUser = (data) => {
        const {users} = this.state;
        const old_user = users.find(i => i.id===data.id);
        users.splice(this.state.users.indexOf(old_user), 1, data);

        this.setState({
            users: users
        })
    };

    render() {

        const {newUser, users, search:searchString} = this.state;
        const list_users = !searchString ? users : this.searchByName();

        return(
            <div className='users'>
                <div role="main" className="row no-gutters justify-content-center">
                    <div className="container">
                        {/*хлебные крошки*/}
                        <Breadcrums address='Пользователи'/>
                        {/*//*/}
                        <div className="row mt-3">
                            <h3 className="col" style={{padding: '0 15px'}}>
                                Пользователи
                            </h3>
                        </div>
                        <div className="row">
                            <div className="col">
                                {/*создать пользователя*/}
                                <CreatingForm onSubmit={this.createUser} created={!!newUser} />
                                {this.state.showModal && <Modal message={this.state.showModal} close={() => {this.setState({showModal: false})}}/>}
                                {/*//*/}
                                {/*// фильтр*/}
                                <div className="row filter mt-2" style={{padding: '0 15px 15px'}}>
                                    <div className="col-xl-5 col-lg-6 col-md-6 col-sm-7 col-xs-7">
                                        <div className="row">
                                            <div className='icon_search'>
                                                <img src={search} alt="search"/>
                                            </div>
                                            <input type="text" onChange={this.handleUserSearch} placeholder='Поиск по логину, ФИО'/>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-6 col-md-6 col-sm-7 col-xs-7">
                                        <div className="row">
                                            <div className='icon_search'>
                                                <img src={search} alt="search"/>
                                            </div>
                                            <input type="text" placeholder='Поиск по номеру объекта'/>
                                        </div>
                                    </div>
                                </div>
                                {/*//*/}
                                {/*// таблица*/}
                                <table className="table table-hover table-bordered small w-100">
                                    <thead className="text-center">
                                        <tr>
                                            <th scope="col" className=" d-none d-sm-table-cell">№ п/п</th>
                                            <th scope="col">Логин</th>
                                            <th scope="col" className=" d-none d-sm-table-cell">Ф.И.О</th>
                                            <th scope="col">E-mail</th>
                                            <th scope="col" className=" d-none d-sm-table-cell">Последний вход</th>
                                            <th scope="col">Функции</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        list_users.map((item, i) => {
                                            return(
                                                <User
                                                    key={`${item.id}_${i}`}
                                                    index={i + 1}
                                                    instance={item}
                                                    onClick={this.checkUser.bind(this, item.id)}
                                                    updateUsersList={this.loadUsersList}
                                                />
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                                {/*//*/}
                            </div>
                            {/*// редактирование пользователя*/}
                                <div className="col-3 d-lg-block d-none">
                                    <EditUser
                                        list_users={list_users}
                                        updateUser={this.updateUser}
                                        checked={this.state.users.find(i => i.checked)}
                                    />
                                </div>
                            {/*//*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Users