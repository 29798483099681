import React, {Component} from "react";
import {Link} from "react-router-dom";
import axios from "axios";

export default class User extends Component {

    toggleUserActiveStatus = () => {

        const {id, is_active} = this.props.instance;

        axios.patch(`/api/user/${id}/`, {is_active: !is_active})
            .then(() => {
                this.props.updateUsersList();
                }
            )
    };


    deleteUser = () => {
        const {id} = this.props.instance;
        axios.delete(`/api/user/${id}/`)
            .then(() => {
                this.props.updateUsersList();
            })
    };

    render() {
        const {username, last_login, first_name,
            last_name, is_active, email, id, checked} = this.props.instance;

        let date = new Date(last_login);

        date = last_login && date.toLocaleString();
        return (
            <tr onClick={this.props.onClick} className={checked ? "bg-light": null}>
                <th scope="row" className=" d-none d-sm-table-cell">{this.props.index}</th>
                <td>{username}</td>
                <td className=" d-none d-sm-table-cell">{first_name + last_name}</td>
                <td>{email}</td>
                <td className=" d-none d-sm-table-cell">{date}</td>
                <td>
                    <div className="row d-block d-lg-none">
                        <div className="col pb-1">
                            <button className={'btn '}>
                                <Link to={`/user/${id}/edit`}>
                                    <i className="fas fa-edit" />
                                </Link>
                            </button>
                        </div>
                        <div className="dropdown col">
                            <button className="btn dropdown-toggle" type="button" id="userSettings" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-sliders-h" />
                            </button>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userSettings">
                                <div className="dropdown-item">
                                    <label htmlFor={`active_user_${id}`} className="pl-4 form-check-label">
                                        <input defaultChecked={is_active} id={`active_user_${id}`} type="checkbox"
                                            onClick={this.toggleUserActiveStatus}
                                           className="form-check-input"/>
                                        включен
                                    </label>
                                </div>
                                <div className="dropdown-item">
                                    <button
                                        className='btn btn-sm btn-outline-danger'
                                        onClick={this.deleteUser}
                                    >
                                        Удалить
                                    </button>
                                </div>
                                <div className="dropdown-item">
                                    <Link to={`/permissions/${id}/`}>
                                        <button className='btn btn-sm'>
                                            Настроить права
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters align-items-baseline justify-content-center d-none d-lg-flex">

                        <div className="col pr-4">
                            <button
                                className='btn btn-sm btn-outline-danger'
                                onClick={this.deleteUser}
                            >
                                Удалить
                            </button>
                        </div>
                        <div className="col form-check">
                            <label htmlFor={`active_user_${id}`} className="form-check-label">
                                <input defaultChecked={is_active} id={`active_user_${id}`} type="checkbox"
                                       onClick={this.toggleUserActiveStatus}
                                       className="form-check-input"/>
                                включен
                            </label>

                        </div>
                        <div className="col" style={{paddingLeft: '0'}}>
                            <Link to={`/permissions/${id}/`}>
                                <button className='btn btn-sm'>
                                    Настроить права
                                </button>
                            </Link>
                        </div>
                    </div>
                </td>
            </tr>
        );
    }
}