import React, {Component, Fragment} from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import axios from "axios/index";

import AuthMain from "./auth/authMain";
import RestorePassword from "./auth/restorePassword";
import Connect from "./auth/connect";
import LkMain from "./lk/lkMain";
import Users from './lk/users/Users';
import PageNews from './lk/news/PageNews';
import ObjectCard from './lk/object/ObjectCard';
import Statistic from "./lk/object/Statistic";
import Params from "./lk/params/Params";
import Feedback from "./lk/object/Feedback";
import Settings from './lk/users/Settings';
import Permissions from "./lk/users/Permissions";
import EnterPass from "./auth/EnterPass";
import Header from "./Header";

import {ThemePropTypes} from "./contexts";
import Messages from "./lk/common/Messages";
import Logout from "./lk/users/logout";
import EditUserPage from "./lk/users/editUserPage";
// import {Page404} from "./lk/common/errors";


class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            toLogin: null,
            set: {
                logo: null,
                name: null,
                phone: null
            }
        }

    }
    getChildContext() {
        return {
            logo: this.state.set.logo,
            name: this.state.set.name,
            phone: this.state.set.phone,
            user: this.state.user
        }
    }

    clearMessages = () => {
        let user = Object.assign({}, this.state.user);
        user.messages = [];
        this.setState({
            user: user
        })
    };

    componentDidMount() {
        let path = window.location.pathname.split('?')[0];
        axios.get('/api/user/initial/').then(res => {
                this.setState({
                    set: res.data
                })
            });
        axios.get('/api/user/info/').then(res => {
          this.setState({
              user: res.data
          })
        }).catch(() => {
          if(path !== '/register/password' && path !== '/login') {
              this.setState({
                  toLogin: true
              })
          }
        });
    }

    static childContextTypes = ThemePropTypes;

    render() {
        const {user, toLogin, set} = this.state;

        return (set ?
            <Fragment>
                <Router>
                    <Fragment>

                        {user && user.messages.length ? <Messages messages={user.messages} clearMessages={this.clearMessages}/> : null}
                        <Switch>
                            <Route exact path="/register/password/:token/" component={EnterPass}/>
                            <Route exact path="/login" component={AuthMain} />
                            <Route exact path="/register/connect" component={Connect} />
                            <Route exact path="/restore-password" component={RestorePassword} />
                            {toLogin ? <Redirect to={'/login'}/> : null}
                            {user ?
                            <Fragment>
                                <Route path="" component={Header}/>
                                <Route exact path="/" component={LkMain}/>
                                <Route exact path="/users" component={Users}/>
                                <Route exact path="/news" component={PageNews}/>
                                <Route exact path="/obj" component={ObjectCard}/>
                                <Route exact path="/stat" component={Statistic}/>
                                <Route exact path="/feedback" component={Feedback}/>
                                <Route exact path="/params" component={Params}/>
                                <Route exact path="/settings/:permissionId/" component={Settings}/>
                                <Route exact path="/permissions/:userId/" component={Permissions}/>
                                <Route exact path="/logout" component={Logout}/>
                                <Route exact path="/user/:userId/edit/" component={EditUserPage} />
                                {/*<Route exact path={"/404"} component={Page404} />
                                <Redirect to={'/404'}/>*/}
                            </Fragment>
                                : null
                            }
                        </Switch>
                    </Fragment>
                </Router>
            </Fragment>
                : null
        );
    }
}

export default App;
// check servers hooks 2