import React, {Component} from "react";
import axios from "axios";

import EditUser from "./EditUser";


class EditUserPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null
        }
    }
    componentDidMount(){
        const {userId} = this.props.match.params;
        axios.get(`/api/user/${userId}/`)
            .then(res => {
                this.setState({user: res.data});
                console.log(res.data)
            });

    }

    render() {
        return (
            <div className="container mt-5">
                <div className="row no-gutters mt-2 justify-content-center">
                    <div className="col col-sm-6">
                        <EditUser
                            isPage={true}
                            checked={this.state.user}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default EditUserPage;