import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import styled from 'styled-components'
import axios from "axios/index";

import "./custom.css"
import News from "../lk/news/News";

class AuthMain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            news: [],
            username: '',
            password: '',
        }
    }

    componentDidMount() {
        axios.get('/api/news/list/')
            .then(res => {
                this.setState({
                    news: res.data
                })
            })
    }

    handleChangeInput = (e, field) => {
        this.setState({[field]: e.target.value})
        e.target.setCustomValidity('')
    }

    invalidFieldHandler = (e) => {
        e.target.setCustomValidity('Заполните это поле')
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            username: this.state.username,
            password: this.state.password
        }
        axios.post('/api/user/login/', data)
            .then(res => {
               if (res.data.success) {
                    document.location.href = '/'
               }
            })
            .catch(err => {
                if (err.response.status === 400) {
                    this.setState({
                        errors: err.response.data['non_field_errors']
                    })
                }
                else {
                    this.setState({
                        errors: ['Что-то пошло не так...']
                    })
                }
            })
    };

    render() {
        const {username, password, errors, news} = this.state

        return (
            <Fragment>
                <Content>
                    <div className="form">
                        <h2 className="title">ВХОД В ЛИЧНЫЙ КАБИНЕТ</h2>
                        <form className="authForm" onSubmit={this.handleSubmit}>
                            <label className="field">
                                Логин
                                <input className="field__input" type="text"
                                       onChange={(e) => this.handleChangeInput(e, 'username')}
                                       value={username}
                                       required
                                       onInvalid={this.invalidFieldHandler}
                                />
                            </label>
                            <label className="field">
                                Пароль
                                <input className="field__input" type="password"
                                       onChange={(e) => this.handleChangeInput(e, 'password')}
                                       value={password}
                                       required
                                       onInvalid={this.invalidFieldHandler}
                                />
                            </label>
                            <div className="buttons">
                                <div className="forgot">
                                    <Link className="forgot__link" to="/register/connect">
                                        Регистрация
                                    </Link>
                                    <Link className="forgot__link" to="/restore-password">
                                        Восстановить пароль
                                    </Link>
                                </div>
                                <button className="loginBtn">ВОЙТИ</button>
                            </div>
                            <div className="errors">
                                {
                                    errors.map((error, index) => (
                                        <div key={`${index}__error`}>
                                            {error}
                                        </div>
                                    ))
                                }
                            </div>
                        </form>
                    </div>
                </Content>
                {
                    news.map((i, index) => (
                        <News
                            key={`${index}_news`}
                            instance={i}
                            readOnly={true}
                        />
                    ))
                }
            </Fragment>
        )
    }
}

const Content = styled.div`
  padding: 0 12px;

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 700px;
    min-height: 300px;
    margin: 3rem auto;
    padding: 50px 100px;
    background-color: #E5F3FC;

    @media (max-width: 600px) {
      flex-direction: column;
      min-height: 350px;
      padding: 50px 30px;
    }
  }

  .title {
    font-size: 28px;
    line-height: 38px;
    text-align: center;

    @media (max-width: 600px) {
      font-size: 22px;
      line-height: 28px;
    }
  }

  .authForm {
    width: 100%;
    margin-top: 20px;
  }

  .field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: 20px;

      @media (max-width: 767px) {
        margin-bottom: 5px;
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }

    &__input {
      flex-grow: 1;
      margin-left: 10px;
      border: none;
      max-width: 400px;
      height: 30px;
      padding: 0 5px;

      @media (max-width: 767px) {
        margin-left: 0;
        margin-top: 3px;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 100px;
    margin-top: 10px;

    @media (max-width: 767px) {
      flex-direction: column;
      padding-left: 0;
    }

    .forgot {
      display: flex;
      flex-direction: column;

      @media (max-width: 767px) {
        align-items: center;
      }

      &__text {
        color: gray;
      }

      &__link {
        color: black;
        text-decoration: underline;
      }
    }

    .loginBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 200px;
      height: 30px;
      padding: 2px 0;
      background-color: lightgray;
      font-size: 18px;
      cursor: pointer;
      transition: 0.3s;
      color: white;
      text-align: center;
      border: none;

      @media (max-width: 767px) {
        order: -1;
        margin: 15px 0;
      }

      @media (max-width: 600px) {
        font-size: 16px;
        line-height: 18px;
        padding: 4px 0;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: #9c9c9c;
        text-decoration: none;
      }
    }
  }

  .errors {
    font-size: 16px;
    color: red;
    text-align: center;
    margin: 10px 0 -30px 0;
  }
`

export default AuthMain;
