import React, {Component} from "react";
import Breadcrums from '../common/Breadcrums';
import axios from 'axios';
import News from "./News";

class PageNews extends Component {
    constructor(props) {
        super(props);
        this.addNew = this.addNew.bind(this);
        this.delNew = this.delNew.bind(this);
        this.state = {
            news: []
        }
    }

    addNew() {
        axios.post('/api/news/add/', {
            text: this.new.value,
        }).then(() => {
            this.new.value = '';
            this.componentDidMount();
        });
    }

    delNew(id) {
        axios.delete(`/api/news/${id}/`).then(() => {
            this.componentDidMount();
        });
    }

    componentDidMount() {
        axios.get('/api/news/list/').then(res => {
            this.setState({
                news: res.data
            });
        });
    }

    render() {
        return (
            <div className='news'>
                <div role="main" className="mt-5 row no-gutters justify-content-center">
                    <div className="container">
                        <Breadcrums address='Новости'/>
                        <div className="row mt-3">
                            <h3 className="col">
                                Новости
                            </h3>
                        </div>
                        <div className="row mt-3">
                            <div className="col">
                                <textarea rows={8} ref={node => this.new = node}/>
                            </div>
                        </div>
                        <div className="row no-gutters mt-3">
                                <button className='btn active col-6 col-sm-5 col-lg-3 m-auto mr-sm-3'
                                        onClick={this.addNew}
                                >Добавить новость</button>
                        </div>
                        <div className="mt-3">
                            {
                                this.state.news.map((item, i) => (
                                    <News
                                        key={`${i}__news`}
                                        readOnly={false}
                                        index={i}
                                        instance={item}
                                        delNews={this.delNew}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PageNews;