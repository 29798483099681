import React, {Component} from "react";
import {Link} from "react-router-dom";
import "./header.css";
import buttonsList from "./buttons";
import Button from "./button";
import {ThemePropTypes} from "../contexts";


const Info = (props) => (
    <div className="navbar-header mr-auto">
            <Link to="/">
                <img src={props.logo} className="logo float-left pb-1 pb-sm-0 img-responsive" width="80px" alt="logo"/>
            </Link>
            <div className="header-info pt-2">
                <div className="row no-gutters fz-header">
                    <div className="coltext-truncate">{props.name}</div>
                </div>
                <div className="row  no-gutters fz-header">
                    <div className="col text-truncate">{props.phone}</div>
                </div>
            </div>
    </div>
);



class Header extends Component {



    static contextTypes = ThemePropTypes;

    render() {
        const {logo, name, phone, user} = this.context;
        const {pathname} = this.props.location;

        return (
            <nav className='header navbar navbar-expand-lg fixed-top bg-white navbar-light pt-lg-0 pb-lg-0'>
                <div className="container-fluid">
                    <Info
                        logo={logo}
                        name={name}
                        phone={phone}
                    />
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#collapse-header">
                        <span className="fas fa-th-list"/>
                    </button>

                    <div className="collapse navbar-collapse" id="collapse-header">
                        <ul className="nav navbar-nav ml-auto">
                            {
                                buttonsList.map((item, index) => {
                                    if (item.staff && !user.is_staff) {
                                        return null
                                    }
                                    return <Button
                                        key={`header_button_${index}`}
                                        instance={item}
                                        isActive={item.slug.test(pathname)}
                                    />
                                })

                            }
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Header;