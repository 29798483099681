const  buttonsList = [
    {
        name: "Пользователи",
        slug: /^\/(users|permissions|settings|user)/,
        path: '/users',
        icon: "user",
        staff: true,
    },
    {
        name: "Обратная связь",
        slug: /^\/feedback$/,
        path: '/feedback',
        icon: "envelope",
        staff: true
    },
    {
        name: "Новости",
        slug: /^\/news$/,
        path: '/news',
        icon: "newspaper",
        staff: true
    },
    {
        name: "Параметры",
        slug: /^\/params$/,
        path: '/params',
        icon: "cogs",
        staff: true,
    },
    {
        name: "Выход",
        slug: /^\/logout$/, // not important
        path: '/logout',
        icon: "arrow-alt-circle-right",
        staff: false
    }
];

export default buttonsList;