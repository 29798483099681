import React, {Component} from "react";
import Breadcrums from "../common/Breadcrums";
import axios from 'axios';
import ObjectLine from "./ObjectLine";

class Statistic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            objs: [],
            interval: 0
        };
        this.filter = this.filter.bind(this);
    }

    filter(interval) {
      axios.get(`/api/objects/object_status/${this.id}/?interval=${interval}`, {withCredentials: true}).then(res => {
          this.setState({
            objs: res.data.messages,
            interval: interval
          });
      });
    }

    componentDidMount() {
        this.id = window.location.search.replace('?', '').split('=')[1];
        axios.get(`/api/objects/object_status/${this.id}/`, {withCredentials: true}).then(res => {
            this.setState({
                objs : res.data.messages
            });
        });
    }

    render() {
        return(
            <div className='statistic'>
                <div role="main" className="mt-5 row justify-content-center">
                    <div className="col-9">
                        <Breadcrums address='Статистика'/>
                        <div className="row mt-3">
                            <div className="col-3">
                                <div className="row">
                                    <div className="col-3">
                                        <span className={'link'} style={{textDecoration: this.state.interval === 1 ? 'underline' : ''}} onClick={() => {this.filter(1)}}>
                                            За день
                                        </span>
                                    </div>
                                    <div className="col-4" style={{borderLeft: '1px solid black', borderRight: '1px solid black'}}>
                                        <span className={'link'} style={{textDecoration: this.state.interval === 7 ? 'underline' : ''}} onClick={() => {this.filter(7)}}>
                                            За неделю
                                        </span>
                                    </div>
                                    <div className="col-4">
                                        <span className={'link'} style={{textDecoration: this.state.interval === 30 ? 'underline' : ''}} onClick={() => {this.filter(30)}}>
                                            За месяц
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 offset-5">
                                <div className="row">
                                    <div className="col-3">Экспорт в</div>
                                    <div className="col-2">
                                        <a className='link'
                                           href={`/api/objects/object_status/${this.id}/pdf/?interval=${this.state.interval}`}
                                           download={"log.pdf"}
                                        >
                                            PDF
                                        </a>
                                    </div>
                                    <div className="col-2" style={{borderRight: '1px solid black'}}>
                                        <a className='link'
                                           href={`/api/objects/object_status/${this.id}/xls/?interval=${this.state.interval}`}
                                           download={"log.xls"}
                                        >
                                            Excel
                                        </a>
                                    </div>
                                    <div className="col-3">
                                        <span className='link'>
                                            Печать
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row table mt-3">
                            <div className="col">
                                <div className="row header">
                                    <div className="col-1">Дата</div>
                                    <div className="col-1">Время</div>
                                    <div className="col-2">Объект</div>
                                    <div className="col-2">Важность</div>
                                    <div className="col-6">Текст сообщения</div>
                                </div>
                                {
                                    this.state.objs.map((item, i) => {
                                        return(
                                          <ObjectLine key={i} item={item}/>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Statistic;