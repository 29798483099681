import React, {Component} from "react";
import {Link} from "react-router-dom";

class Breadcrums extends Component {

    render() {
        return (
            <div className="row pt-2">
                <div className="col d-flex navigation align-items-center">
                    <Link to="/">Главная</Link> {this.props.users && <span> / <Link to="/users">Пользователи</Link> {this.props.access && <span>/ <Link to="/permissions">Доступ</Link></span>}</span>} / {this.props.address}
                </div>
            </div>
        );
    }
}

export default Breadcrums;