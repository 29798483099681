import React, {Component, Fragment} from "react";

const DelButton = (props) => (
    <button className='col-5 col-sm-2 btn btn-sm btn-outline-danger'
            onClick={props.onClick}>
        Удалить
    </button>
);


class News extends Component {

    delNews = () => {
        this.props.delNews(this.props.instance.id);
    };

    render() {
        const {readOnly, instance} = this.props;
        let dateString = new Date(instance.datetime);
        dateString = dateString.toLocaleString("ru");

        return (
            <Fragment>
                <div className="row no-gutters justify-content-center">
                    <div className="">
                        <div className="card news__item mb-1 p-2 p-lg-4">
                            <div className="row no-gutters justify-content-between">
                                 <span className="col-6 col-sm-3 text-muted small pt-2">
                                    {dateString}
                                 </span>
                                {!readOnly ? <DelButton onClick={this.delNews} /> : null}

                            </div>
                            <div className="card-text mt-2 mb-2">
                                {instance.text}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default News;