import React, {Component, Fragment} from "react";
import axios from "axios/index";
import {Redirect} from "react-router-dom";


const userDefault = {
    username: '',
    first_name: '',
    email: '',
    is_staff: false
};

class EditUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: this.props.checked || userDefault,
            resetPass: false,
            updatedUser: false
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.checked !== this.props.checked) {
            this.setState({
                user: newProps.checked || userDefault
            })
        }

    }
    componentWillUnmount() {
        clearTimeout(this.resetPass);
        clearTimeout(this.updatedUser);
    }

    handleChangeInput = (e) => {
        const user = Object.assign({}, this.state.user);
        user[e.target.name] = e.target.value;
        this.setState({
            user: user
        });
    };

    handleChangeCheckbox = (e) => {
        let user = Object.assign({}, this.state.user);
        user[e.target.name] = e.target.checked;
        this.setState({
            user: user
        })
    };

    updatingIndication = () => {
        this.setState({
            updatedUser: true
        });
        this.updatedUser = setTimeout(() => {
            this.setState({
                updatedUser: false
            })
        }, 2000)
    };
    completeResetPassword = () => {
        this.setState({
           resetPass: true
        });
        this.resetPass = setTimeout(() => {
            this.setState({
                resetPass: false
            })
        }, 2000)
    };

    handleSubmit = (e) => {
        e.preventDefault();

        let data = new FormData(e.target);
        data.set('is_staff', data.has('is_staff') ? '1' : '0');
        axios.patch(`/api/user/${this.props.checked.id}/`, data)
            .then(res => {
                if (this.props.isPage) {
                    this.setState({
                        redirect: true
                    })
                } else {
                    this.props.updateUser(res.data);
                    this.updatingIndication();
                }
            })

    };

    resetValues = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.isPage) {
            return this.setState({
                redirect: true
            })
        }
        this.setState({
            user: this.props.checked || userDefault
        })
    };
    resetPassword = () => {
        axios.patch(`/api/user/${this.state.user.id}/reset_password/`)
            .then(res => {
                if (res.data.success) {
                    this.completeResetPassword()
                }
            })
    };

    render() {

        const {user, updatedUser, redirect} = this.state;

        return (
            <Fragment>
                {redirect && <Redirect to="/users"/>}
                <form onSubmit={this.handleSubmit}>
                    <div className="card">
                        <div className="card-header small text-center">
                            Редактирование пользователя
                        </div>
                        <div className="card-body p-2">
                            <div className="row mt-3">
                                <div className="col">
                            <span>
                                Логин
                            </span>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col">
                                    <input type="text"
                                           required
                                           name="username"
                                           className="form-control"
                                           disabled={!user.id}
                                           value={user.username}
                                           onChange={this.handleChangeInput}
                                           placeholder='Логин'/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                            <span>
                                                Ф.И.О.
                                            </span>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col">
                                    <input type="text"
                                           name="first_name"
                                           className="form-control"
                                           disabled={!user.id}
                                           value={user.first_name}
                                           onChange={this.handleChangeInput}
                                           placeholder='Ф.И.О.'/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                            <span>
                                                E-mail
                                            </span>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col">
                                    <input type="email"
                                           required
                                           disabled={!user.id}
                                           className="form-control"
                                           name={"email"}
                                           value={user.email}
                                           onChange={this.handleChangeInput}
                                           placeholder='E-mail'/>
                                </div>
                            </div>
                            <div className="row mt-3 no-gutters justify-content-around">
                                <div className="col-5 text-center pt-2">
                                    <label className={!user.id ? 'text-secondary': null}>
                                        <input type="checkbox"
                                               name={"is_staff"}
                                               disabled={!user.id}
                                               checked={user.is_staff}
                                               onChange={this.handleChangeCheckbox}
                                        />
                                        Админ
                                    </label>
                                </div>
                                <button type="reset" className='btn col-7'
                                        onClick={this.resetPassword}
                                        disabled={!user.id}
                                        style={{padding: '5px 0px'}}>
                                    Сбросить пароль
                                </button>
                            </div>
                            <div className="row justify-content-between no-gutters mt-3 mb-3">
                                <button className='col-5 btn btn-success' disabled={!user.id}>
                                    <i className="fas fa-success" />
                                    {updatedUser ? 'Сохранено': 'Сохранить'}
                                </button>
                                <button disabled={!user.id}
                                        type="reset"
                                        onClick={this.resetValues}
                                        className='btn btn_cancel col-5'
                                >
                                    Отмена
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Fragment>
        )
    }
}

export default EditUser;