import {Component} from "react";
import React from "react";

class ObjectLine extends Component {
    constructor(props) {
        super(props);
        this.messageColor = this.messageColor.bind(this);
    }

    messageColor(color) {
        switch(this.props.item.color) {
          case 1:
              return 'rgba(241, 0, 0, 0.46)';
          case 2:
              return 'rgba(0, 160, 209, 0.57)';
          default:
              return 'rgb(0, 241, 146)';
        }
    }

    render() {
        const {item} = this.props;
        return(
            <div className="row" style={{borderTop: '1px solid #ddd'}}>
                <div className="col-1 text-center">{item.date}</div>
                <div className="col-1 text-center">{item.time}</div>
                <div className="col-2 text-center">{item.object_name}</div>
                <div className="col-2 text-center">
                   {item.status}
                </div>
                <div className="col-6" style={{background: this.messageColor()}}>{item.text}</div>
            </div>
        )
    }
}

export default ObjectLine;