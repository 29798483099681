import React, {Component} from "react";
import Breadcrums from '../common/Breadcrums';
import axios from 'axios';

class ObjectCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            staff: false,
            id: null,
            name: null,
            address: null
        };
        this.updateObject = this.updateObject.bind(this);
    }

    updateObject() {
        axios.patch(`/api/objects/object/${this.state.id}/`, {
            name: this.input.value,
            address: this.textarea.value,
        }).then(res => {
            console.log(res.data);
        });
    }

    componentWillMount() {
        axios.get('/api/user/info/').then(res => {
            this.staff = res.data.is_staff;
        });
    }

    componentDidMount() {
        let id = window.location.search.replace('?', '').split('=')[1];
        axios.get(`/api/objects/object/${id}/`).then(res => {
            this.setState({
                id: res.data.id,
                name: res.data.name,
                address: res.data.address,
                staff: this.staff
            });
        });
    }

    render() {
        return(
            <div className='objectCard'>
                <div role="main" className="mt-5 row justify-content-center">
                    <div className="col-9">
                        <Breadcrums address='Карточка объекта'/>
                        <div className="row mt-3 number">
                            <div className="col-1">
                                <span>Номер</span>
                            </div>
                            <div className="col-5">
                                {this.state.id}
                            </div>
                        </div>
                        <div className="row mt-3 name">
                            <div className="col-1">
                                <span>Название</span>
                            </div>
                            <div className={`col-5 ${!this.state.staff && 'disabled'}`}>
                                {
                                    !this.state.staff ?
                                        this.state.name
                                        : <input ref={node => this.input = node} type="text" defaultValue={this.state.name}/>
                                }
                            </div>
                        </div>
                        <div className="row mt-3 addres">
                            <div className="col-1">
                                <span>Адрес</span>
                            </div>
                            <div className={`col-5 ${!this.state.staff && 'disabled'}`}>
                                {
                                    !this.state.staff ?
                                        this.state.address
                                        : <textarea ref={node => this.textarea = node} defaultValue={this.state.address}/>
                                }
                            </div>
                        </div>
                        {
                            this.state.staff &&
                            <div className="row mt-2">
                                <div className="col-1 offset-5">
                                    <button className='btn_save' onClick={this.updateObject}>Сохранить</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ObjectCard;