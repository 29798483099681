import React, {Component} from "react";
import search from '../imgs/search.png';
import axios from 'axios';
import "./lkStyles.css";
import {Link} from "react-router-dom";

const DefaultLine = {
    borderTop: '1px solid #ddd',
    padding: '3px 0'
};

const MarkedLine = {
    borderTop: '1px solid #ddd',
    padding: '3px 0',
    backgroundColor: '#F08080'
};

const ObjectLine = (props) => {
    let paymentRequest = (o_id) => {
        axios.post('api/objects/payment/' + o_id + '/').then(res=>{
            window.location.href = res.data.payment_url
        })
    }

    return (
    <div className="row align-items-center" style={props.balance >= 0 ? DefaultLine : MarkedLine}>
        <div className="col" style={{textAlign: 'center'}}>{props.id}</div>
        <div className="col">{props.name}</div>
        <div className="col">{props.address}</div>
        <div className="col" style={{textAlign: 'center'}}>
            {
                props.is_locked ?
                    <Link to={'/stat?id=' + props.id} style={{color: '#00A0D3'}}>
                        <i className="fas fa-fw pr-1 fa-lock"/>
                        Под охраной
                    </Link>
                    :
                    <Link to={'/stat?id=' + props.id}>
                        <i className="fas fa-fw pr-1 fa-lock-open"/>
                        Снят с охраны
                    </Link>
            }
        </div>
        <div className="col" style={{textAlign: 'center'}}>
            {
                <span onClick={ () => paymentRequest(props.id)} style={{textDecoration: 'underline', cursor: 'pointer', color: '#3e7dec'}}>{props.balance}</span>
            }
        </div>
    </div>
    )
};

class LkMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            objects: [],
            searchField: ''
        }
    }

    componentDidMount() {
        axios.get('/api/objects/object/').then(res => {
            this.setState({
                objects: Array.isArray(res.data) ? res.data : []
            });
        });
    }

    search = (e) => {
 this.setState({
                searchField: e.target.value
        })
    };

    render() {
        const {searchField} = this.state;
        let objects =  searchField
            ? this.state.objects.filter(i => String(i.o_id).includes(searchField)
                                             || (i.address && i.address.includes(searchField))
                                             || (i.name && i.name.includes(searchField))
                                        )
            : this.state.objects;
        return (
            <div className='main'>
                <main role="main" className="mt-5 row justify-content-center">
                    <div className="col-lg-9">
                        <div className="row pt-4">
                            <div className="col d-flex inner-form">
                                <div className="input-field">
                                    <button className="btn-search" type="button">
                                        <img src={search} alt="search" style={{width: '24px', height: '24px'}}/>
                                    </button>
                                    <input id="search" onChange={this.search} type="text" placeholder="Поиск по номеру объекта" />
                                </div>
                            </div>
                        </div>
                        {/*
                        <div className="row pt-3">
                            <div className="col d-flex search align-items-center">
                                <img src={search} alt="search"/>
                                <input type="text" onChange={this.search} placeholder='Поиск по номеру объекта'/>
                            </div>
                        </div>
                        */}
                        <div className="row pt-5">
                            <div className="col">
                                <div className="row" style={{fontWeight: 'bold', paddingBottom: '5px', textAlign: 'center'}}>
                                    <div className="col">Номер</div>
                                    <div className="col">Название</div>
                                    <div className="col">Адрес</div>
                                    <div className="col">Состояние</div>
                                    <div className="col">Баланс</div>
                                </div>
                                {
                                    objects.map((item, i) => (
                                            <ObjectLine
                                                key={`${item.id}_${i}`}
                                                id={item.o_id}
                                                is_locked={item.is_under_guard}
                                                name={item.name}
                                                address={item.address}
                                                balance={ item.balance != null ? item.balance*-1 : null}
                                            />
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

export default LkMain;