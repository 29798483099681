import React, {Component} from "react";
import {Link} from "react-router-dom";


class Button extends Component {
    render() {
        const {name, icon, path} = this.props.instance;

        return (
            <li className={`nav-item p-md-2 p-0 ${this.props.isActive ? 'active':null}`}>
                <Link to={`${path}`} className="nav-link">
                    <i className={`fas fa-lg fa-fw fa-${icon}`}/>
                    <span> {name}</span>
                </Link>
            </li>
        )
    }
}

export default Button;