import React, {Component} from "react";
import Breadcrums from '../common/Breadcrums';
import axios from 'axios';

class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            success: false
        };
        this.send = this.send.bind(this);
    }

    send() {
        axios.post('/api/user/messages/new/', {text: this.text.value}).then(res => {
            console.log(res.data);
            this.text.value = '';
            this.setState({
                success: true
            });
            setTimeout(() => {
                this.setState({
                    success: false
                });
            }, 2000);
        });
    }

    render() {
        return(
            <div className='feedback'>
                <div role="main" className="mt-5 row no-gutters justify-content-center">
                    <div className="col-9">
                        <Breadcrums address='Обратная связь'/>
                        <div className="row mt-3">
                            <h3 className="col" style={{padding: '0'}}>
                                {/* <img src={mail} alt="mail"/> */}
                                Обратная связь
                            </h3>
                        </div>
                        <div className="row mt-3">Новое сообщение:</div>
                        <div className="row mt-3">
                            <textarea rows={5} ref={node => this.text = node}/>
                        </div>
                        <div className="row mt-3 no-gutters">
                            <div className="col ml-auto col-sm-3" style={{paddingRight: '0'}}>
                                <div className="send-btn mb-2" onClick={this.send}>Отправить</div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.success && <div className="popup">Сообщение отправлено</div>
                }
            </div>
        );
    }
}

export default Feedback;