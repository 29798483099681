import React, {Component, Fragment} from "react";
import {Redirect} from "react-router-dom";
import logo from '../imgs/logo.png';
import axios from 'axios';

class EnterPass extends Component {
    constructor(props) {
        super(props);
        this.token = this.props.match.params.token;
        this.getInfo = this.getInfo.bind(this);
        this.state = {
            redirect: null,
            password: '',
            confirm: '',
            isValid: false,
            isShowError: false
        }
    }

    checkPasswords = () => {
        const isValid = this.state.password === this.state.confirm;
        this.setState({
            isValid: isValid,
            isShowError: !isValid && !!this.state.confirm
        });
        return isValid
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.checkPasswords()) return;
        axios.patch(`/api/user/confirm/${this.token}/`, {
            password: this.state.password,
        }).then(() => {
            window.location.href = '/';
        })
    };
    handleInputChange = (e) => {
        let data = {};
        data[e.target.name] = e.target.value;
        this.setState(data, this.checkPasswords)
    };

    componentWillMount() {
        this.getInfo();
    }

    async getInfo() {
        return await axios.get(`/api/user/confirm/${this.token}/`).catch(err => {
            if (err.response.status === 404) {
                this.setState({
                    redirect: true
                })
            }
        })
    }

    render() {
        const {isShowError, redirect, password, confirm} = this.state;
        return(

            <Fragment>
                {redirect && <Redirect to="/404"/>}
                <div style={{width: '100vw', height: '100vh'}}>
                    <div className="form-enter_pass">
                        <h1>Введите пароль</h1>
                        <div className="body">
                            <div>
                                <img src={logo} alt="logotype"/>
                                <div className="inputs">
                                    <div className="row no-gutters justify-content-center pb-2">
                                        <div className="col-auto text-warning btn">
                                            {isShowError && "Пароли не совпадают"}
                                        </div>
                                    </div>
                                    <label>
                                        Пароль
                                        <input
                                            type="password"
                                            required
                                            value={password}
                                            onChange={this.handleInputChange}
                                            name="password"
                                        />
                                    </label>
                                    <label>
                                        Подтвердите
                                        <input
                                            className="input-error"
                                            type="password"
                                            required
                                            name="confirm"
                                            value={confirm}
                                            onChange={this.handleInputChange}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="footer">
                                <button onClick={this.handleSubmit}>Логин</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default EnterPass;