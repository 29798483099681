import React, {Component} from "react";
import axios from "axios/index";
import Breadcrums from '../common/Breadcrums';
import arrow from '../../imgs/arrow-down.png';
import i from '../../imgs/i.png';
import phone from '../../imgs/phone.png';
import email from '../../imgs/email.png';
import server from '../../imgs/server_stack.png';
import lock from '../../imgs/lock.png';
import port from '../../imgs/port.png';
import mail_white from '../../imgs/mail_white.png';
import man from '../../imgs/man.png';
import time from '../../imgs/time.png';

class Params extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mainLogo: null,
            set: null
        }
    }
    componentDidMount() {
      this.getSettings();
    }

    getSettings = () => {
        axios.get('/api/user/settings/')
            .then(res => {
                this.setState({
                    set: res.data,
                    mainLogo: null
                })
            })
    };

    handleSubmitForm = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        if (!this.state.mainLogo) {
            data.delete('main_logo')
        }
        // by default formData doesn't see inputs with value 'false'
        data.set('email_is_SSL', data.has('email_is_SSL') ? '1' : '0');
        data.set('email_is_TLS', data.has('email_is_TLS') ? '1' : '0');
        axios.patch(`/api/user/settings/`, data).then(() => {
            document.location.reload()
        }
        )

    };

    setDefault = (e) => {
        e.preventDefault();
        e.stopPropagation();
        axios.delete('/api/user/settings/')
            .then(() => {
                document.location.reload()
            })
    };
    handleChangeLogo = (e) => {

        this.setState({
            mainLogo: URL.createObjectURL(e.target.files[0])
        })
    };

    render() {


        const {mainLogo, set} = this.state;

        return(set &&
            <div className="params">
                <div role="main" className="mt-5 row justify-content-center">
                    <div className="col-9">
                        <Breadcrums address='Параметры'/>
                        <form onSubmit={this.handleSubmitForm}>
                            <div className="row mt-3 align-items-center">
                                <div className="col-2">
                                    <img
                                        src={mainLogo || set.main_logo_url}
                                        className="mx-auto img-fluid"
                                        alt="logotype"/>
                                </div>
                                <div className="col-3">
                                    <input id="sett_logo" type="file"
                                       accept="image/*"
                                           defaultValue={""}
                                           name={'main_logo'}
                                       onChange={this.handleChangeLogo}
                                       className="d-none"/>

                                        <label htmlFor="sett_logo" className="round-btn">
                                            <img
                                            src={arrow}
                                            alt="arrow"/>
                                            Загрузить логотип
                                        </label>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="row mt-3">
                                        <div className="col-2">
                                            <div className="round-btn">
                                                <img src={i} alt="i"/>
                                                <span className="text-button-2">Название</span>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <input type="text" name="name" defaultValue={set.name}/>
                                        </div>
                                        <div className="col-5">
                                            <div className="round-btn">
                                                Наименование ЧОПа
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-2">
                                            <div className="round-btn">
                                                <img src={phone} alt="phone"/>
                                                <span className="text-button-2">Телефон</span>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <input type="text" name="phone" defaultValue={set.phone}/>
                                        </div>
                                        <div className="col-5">
                                            <div className="round-btn">Номер телефона ЧОПа</div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-2">
                                            <div className="round-btn">
                                                <img src={email} alt="email"/>
                                                <span className="text-button-2">E-mail</span>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <input type="text" name={"email_from"} defaultValue={set.email_from}/>
                                        </div>
                                        <div className="col-5">
                                            <div className="round-btn">E-mail от кого письмо</div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-2">
                                            <div className="round-btn">
                                                <img src={server} alt="server"/>
                                                <span className="text-button-2">SMTP</span>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <input type="text" name={"email_smtp"} defaultValue={set.email_smtp}/>
                                        </div>
                                        <div className="col-5">
                                            <div className="round-btn">SMTP сервер</div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-2">
                                            <div className="round-btn">
                                                <img src={lock} alt="lock"/>
                                                <span className="text-button-2">Пароль</span>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <input type="password" name={"email_password"} defaultValue={set.email_password}/>
                                        </div>
                                        <div className="col-5">
                                            <div className="round-btn">Пароль для входа</div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-2">
                                            <div className="round-btn">
                                                <img src={port} alt="port"/>
                                                <span className="text-button-2">Порт</span>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <input type="text" name={"email_port"} defaultValue={set.email_port}/>
                                        </div>
                                        <div className="col-5">
                                            <div className="round-btn">SMPT порт</div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-5 offset-2">
                                            <div className="round-btn">
                                                <label>
                                                    <input type="checkbox" name={"email_is_TLS"} defaultChecked={set.email_is_TLS}/>
                                                    Вкл/выкл безопасность транспортного уровня
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-5 offset-2">
                                            <div className="round-btn">
                                                <label>
                                                    <input type="checkbox" name={"email_is_SSL"} defaultChecked={set.email_is_SSL}/>
                                                    Включить SSL
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-2">
                                            <div className="round-btn">
                                                <img src={mail_white} alt="mail_white"/>
                                                <span className="text-button-2">Тема</span>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <input type="text" name={"email_subject"} defaultValue={set.email_subject}/>
                                        </div>
                                        <div className="col-5">
                                            <div className="round-btn">Тема письма пользователя</div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-2">
                                            <div className="round-btn">
                                                <img src={man} alt="man"/>
                                                <span className="text-button-2">Логин</span>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <input type="text" name={"email_login"} defaultValue={set.email_login}/>
                                        </div>
                                        <div className="col-5">
                                            <div className="round-btn">Логин</div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-2">
                                            <div className="round-btn">
                                                <img src={time} alt="time"/>
                                                <span className="text-button-2">Время</span>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <input type="text" name={"email_time"} defaultValue={set.email_time}/>
                                        </div>
                                        <div className="col-5">
                                            <div className="round-btn">Максимальное приемлемое время в секундах с момента,
                                                как получили последнее сообщение
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-4 input-email"><input type="text" /></div>
                                        <div className="col-3 send-email">
                                            <div>Отправить текстовое письмо</div>
                                        </div>

                                    </div>
                                    <div className="row mt-4 mb-4">
                                        <div className="col-5 offset-0 offset-sm-2 col-xl-3 col-lg-4 col-md-4 col-sm-4">
                                            <button type="submit" className="save-btn">Сохранить</button>
                                        </div>
                                        <div className="col-5 offset-1 col-xl-3 col-lg-4 col-md-4 col-sm-4">
                                            <button className="btn col" type="reset" onClick={this.setDefault}>По умолчанию</button>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Params;