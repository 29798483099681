import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import axios from "axios/index";
import Breadcrums from '../common/Breadcrums';

const ObjectPlace = (props) => (
    <div className={`row justify-content-between no-gutters border border-gray pl-2 pr-2 m-1 ${props.checked ? "bg-secondary":null}`}
         onClick={props.onClick}>
                <div className="col-auto">{props.name}</div>
                {props.writable
                    ? <Link to={`/settings/${props.p_id}/`} className={`col-auto ${props.checked ? "text-white":null}`}>
                        <span className="d-none d-lg-inline">Настроить</span>
                        <i className="fas fa-cog d-inline d-lg-none" />
                      </Link>
                    : null
                }
    </div>
);

class Permissions extends Component {

    constructor(props) {
        super(props);
        this.userId = this.props.match.params.userId;
        this.state = {
            own_objects: [],
            all_objects: [],
            allSearchString: '',
            ownSearchString: ''
        }
    }
    componentDidMount() {
        this.getPermissions();
    }
    getPermissions = () => {
        axios.get(`/api/objects/owner/${this.userId}/`)
            .then(res => {
                this.setState({
                    username: res.data.username,
                    own_objects: res.data.objects.permissions,
                    all_objects: res.data.objects.all,
                })
            })
    };

    searchAll = (e) => {
        this.setState({
            allSearchString: e.target.value
        })
    };
    searchOwn = (e) => {
        this.setState({
            ownSearchString: e.target.value
        })
    };

    checkPermission = (id) => {
        let new_list = this.state.own_objects;
        // console.log(new_list);
        let p = new_list.find(i => i.id === id);
        new_list.find(i => i.id === id).checked = !p.checked;
        this.setState({
            own_objects: new_list
        })
    };
    checkObject = (id) => {
        let new_list = this.state.all_objects;
        let p = new_list.find(i => i.id === id);
        new_list.find(i => i.id === id).checked = !p.checked;
        this.setState({
            all_objects: new_list
        })
    };

    moveAllTo = (to) => {
        const list = this.state[to ? 'all_objects' : 'own_objects'];
        axios.patch(`/api/objects/owner/${this.userId}/`, {
            objects: list.map(i => i.id),
            action: to ? 'add' : 'delete'
        })
            .then(res => {
                if (res.data.success) {
                    this.getPermissions()
                }
            })
    };
    moveCheckedTo = (to) => {
        const list = this.state[to ? 'all_objects' : 'own_objects'].filter(i => i.checked);
        // console.log(list);
        axios.patch(`/api/objects/owner/${this.userId}/`, {
            objects: list.map(i => i.id),
            action: to ? 'add' : 'delete'
        })
            .then(res => {
                if (res.data.success) {
                    this.getPermissions()
                }
            })
    };

    render() {

        const {all_objects:rawAll, own_objects:rawOwn, username,
            allSearchString, ownSearchString} = this.state;

        const all_objects = allSearchString ? rawAll.filter(i => String(i.name).includes(allSearchString)) : rawAll;
        const own_objects = ownSearchString ? rawOwn.filter(i => String(i.name).includes(ownSearchString)) : rawOwn;
        return(
            <Fragment>
                <div role="main" className="mt-5 row no-gutters justify-content-center">
                    <div className="col col-sm-8">
                        <Breadcrums users={true} address='Права пользователя'/>
                        <div className="row mt-2 mb-2 justify-content-center mr-auto">
                            <h5>Права пользователя: {username}</h5>
                        </div>
                        <div className="row no-gutters justify-content-around">
                            <div className="col-4">
                                <div className="row">
                                    <div className="col input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="fas fa-filter" />
                                            </div>
                                        </div>
                                        <input type="text" className="form-control" onChange={this.searchOwn}/>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-header">
                                                Разрешенные объекты ({own_objects.length})
                                            </div>

                                            <div className="card-body p-1 pre-scrollable">
                                                {
                                                    own_objects.map(i => (
                                                        <ObjectPlace
                                                            key={`user_object${i.id}`}
                                                            id={i.id}
                                                            p_id={i.p_id}
                                                            name={i.name}
                                                            writable={true}
                                                            checked={i.checked}
                                                            onClick={this.checkPermission.bind(this, i.id)}
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-1 m-2">
                                <div className="row mt-5">
                                    <button className="btn btn-sm col"
                                            onClick={this.moveCheckedTo.bind(this, this.userId)}>
                                        &lt;
                                    </button>
                                </div>
                                <div className="row mt-4">
                                    <button className="btn btn-sm col"
                                            onClick={this.moveCheckedTo.bind(this, null)}>
                                        &gt;
                                    </button>
                                </div>
                                <div className="row mt-4">
                                    <button className="btn btn-sm col"
                                            onClick={this.moveAllTo.bind(this, this.userId)}>
                                        &lt;&lt;
                                    </button>
                                </div>
                                <div className="row mt-4">
                                    <button className="btn btn-sm col"
                                            onClick={this.moveAllTo.bind(this, null)}>
                                        &gt;&gt;
                                    </button>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="row">
                                    <div className="col input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="fas fa-filter" />
                                            </div>
                                        </div>
                                        <input type="text" className="form-control" onChange={this.searchAll}/>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-header">
                                                Все oбъекты ({all_objects.length})
                                            </div>
                                            <div className="card-body p-1 pre-scrollable">
                                                {
                                                    all_objects.map(i => (
                                                        <ObjectPlace
                                                            key={`object_${i.id}`}
                                                            name={i.name}
                                                            id={i.o_id}
                                                            writable={false}
                                                            checked={i.checked}
                                                            onClick={this.checkObject.bind(this, i.id)}
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Permissions;