import React, {Component} from"react";


export default class Messages extends Component {

    render() {
        return (
            <div className="row fixed-bottom ml-2 mb-1">
                <div className="col-4">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col">
                                    Уведомления
                                </div>
                                <div className="col">
                                    <button className="close" onClick={this.props.clearMessages}><span aria-hidden="true">&times;</span></button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            {
                                this.props.messages.map(i => (
                                    <div className="row">
                                        <span className="col">
                                            {i.text}
                                        </span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}